import { FC, useState } from "react"
import { useTranslation } from "react-i18next"

import { DeleteConfirmationModal } from "@/components/elements/DeleteButton"
import Menu from "@/components/ui/Menu"
import { HorizontalDots } from "assets/icons"
import { useCurrentUserContext } from "context/CurrentUserContext"

import { CertificateRow } from "./CertificatesTable"
import useCertificateRowAction from "./useCertificateRowAction"

type Props = {
  data: CertificateRow
}

const CertificateRowMenu: FC<Props> = ({ data }) => {
  const { isUser } = useCurrentUserContext()
  const { t: tDownloads } = useTranslation("certificateEditor", {
    keyPrefix: "actions",
  })
  const { t: tActions } = useTranslation("certificatesPage", {
    keyPrefix: "actions",
  })

  const [certificateIdToDelete, setCertificateIdToDelete] = useState<
    number | null
  >(null)

  const {
    downloadCertificateAs,
    duplicateCertificate,
    deleteCertificate,
    editCertificate,
  } = useCertificateRowAction({ data })

  return (
    <>
      <Menu
        options={[
          {
            label: tActions("edit"),
            disabled: data.editDisabled,
            onClick: () => editCertificate(),
          },
          {
            label: tActions("duplicate"),
            disabled: data.duplicateDisabled,
            onClick: () => duplicateCertificate(),
          },
          {
            label: tDownloads("downloadAs", { type: "PDF" }),
            onClick: () => downloadCertificateAs("PDF"),
          },
          {
            label: tDownloads("downloadAs", { type: "DOC" }),
            disabled: isUser,
            onClick: () => downloadCertificateAs("DOCX"),
          },
          {
            type: "separator",
          },
          {
            type: "destructive",
            label: tActions("delete"),
            disabled: data.deleteDisabled,
            onClick: () => setCertificateIdToDelete(data.CertificateID),
          },
        ]}
      >
        <button className="-mx-4 rounded-full p-2 hover:bg-gray-200">
          <HorizontalDots className="h-5 w-5 fill-white " />
        </button>
      </Menu>

      {certificateIdToDelete && (
        <DeleteConfirmationModal
          show={Boolean(certificateIdToDelete)}
          onClose={() => setCertificateIdToDelete(null)}
          onConfirm={() => {
            deleteCertificate(certificateIdToDelete)
          }}
        />
      )}
    </>
  )
}

export default CertificateRowMenu
