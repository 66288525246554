import { useRouter } from "next/router"
import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
  ReactNode,
  useCallback,
} from "react"
import { MutationStatus, QueryObserverBaseResult } from "react-query"

import {
  useEmployee,
  ICertificate,
  useCertificate,
  useCertificateMutation,
  IEmployee,
  ECertificateStatuses,
  IJob,
  ECertificateTypes,
} from "hooks/data"
import { IItem, TDateFormat } from "types"

export enum ECertificateSections {
  CERTIFICATE_TYPE = "CERTIFICATE_TYPE",
  INTRO = "INTRO",
  TASKS = "TASKS",
  CUSTOM_TASK_BLOCKS = "CUSTOM_TASK_BLOCKS",
  AUTHORIZATION = "AUTHORIZATION",
  EDUCATION = "EDUCATION",
  EVALUATION = "EVALUATION",
  CONCLUSION = "CONCLUSION",
  COMPANY_NAME = "COMPANY_NAME",
  SIGNATURES = "SIGNATURES",
  DATE_AND_PLACE = "DATE_AND_PLACE",
}

export enum ESteps {
  BASIC = "basic",
  JOB = "job",
  TASKS = "tasks",
  EDUCATION = "education",
  EVALUATION = "evaluation",
  CONCLUSION = "conclusion",
}

export interface ITraining {
  AusbildungID: number
  Bezeichnung: string
  BisDatum: TDateFormat
  VonDatum: TDateFormat
  Schule: string
}

export interface ISignature {
  Datum: TDateFormat
  UnterschriftsberechtigungID: 1 | 2 | 3
  CatUnterschriftsberechtigungTyp: IItem
}

type TStepProps = {
  active: boolean
  dirty: boolean
}

export interface IDrawer {
  categoriesIds: number[]
  subCategoriesIds: number[]
  selectedEvaluationId: number | undefined | null
  selectedJobId: number | undefined | null
  selectedTaskId: number | undefined | null
}

type TSteps = Record<ESteps, TStepProps>

type TCertificateContext = {
  steps: TSteps
  markStepAsActive: (step: ESteps) => void
  markStepAsDirty: (step: ESteps) => void

  updateCertificate: (
    payload: Partial<ICertificate>
  ) => Promise<ICertificate | undefined>
  certificateUpdateStatus: MutationStatus

  certificate?: ICertificate
  refetchCertificate: QueryObserverBaseResult<ICertificate>["refetch"]

  employee?: IEmployee
  refetchEmployee: QueryObserverBaseResult<IEmployee>["refetch"]

  educationAvailable?: boolean
  evaluationAvailable?: boolean
  assessedEmployee?: IItem
  setAssessedEmployee: ({ ID, DisplayName }: IItem) => void
  activeSection:
    | ECertificateSections
    | `${ECertificateSections.CUSTOM_TASK_BLOCKS}__${number}`
  setActiveSection: (
    section:
      | ECertificateSections
      | `${ECertificateSections.CUSTOM_TASK_BLOCKS}__${number}`
  ) => void
  activeEvaluationSentenceId: number | null
  setActiveEvaluationSentenceId: React.Dispatch<
    React.SetStateAction<number | null>
  >
  isDone: boolean
  drawerState?: IDrawer
  setDrawerState: React.Dispatch<React.SetStateAction<IDrawer>>
  scrollDrawerIntoView: React.MutableRefObject<boolean>
  highlightSelectedEvaluationRow: React.MutableRefObject<boolean>

  selectedJob: IJob | undefined
  setSelectedJob: React.Dispatch<React.SetStateAction<IJob | undefined>>
}

const CertificateContext = createContext<TCertificateContext | null>(null)

type TProps = {
  children: ReactNode
}

function CertificateProvider({ children }: TProps): JSX.Element {
  const { query } = useRouter()
  const certificateId = Number(Array.isArray(query.id) ? query.id[0] : query.id)

  const certificateQuery = useCertificate(certificateId)
  const certificateMutation = useCertificateMutation()

  const updateCertificate = useCallback(
    async (payload: Partial<ICertificate>) => {
      if (certificateQuery.data) {
        return certificateMutation.mutateAsync({
          ...certificateQuery.data,
          ...payload,
        })
      }
    },
    [certificateQuery.data, certificateMutation]
  )

  const employeeId = certificateQuery.data?.BeurteilterMitarbeiter.ID
  const employeeQuery = useEmployee(employeeId)

  const [steps, setSteps] = useState<TSteps>(() =>
    [
      ESteps.BASIC,
      ESteps.JOB,
      ESteps.TASKS,
      ESteps.EDUCATION,
      ESteps.EVALUATION,
      ESteps.CONCLUSION,
    ].reduce(
      (accumulator, name) => ({
        ...accumulator,
        [name]: {
          active: false,
          dirty: false,
        },
      }),
      {} as TSteps
    )
  )

  const markStepAsActive = useCallback(
    (stepId: ESteps) => {
      const markAllStepsAsInactive = () =>
        Object.fromEntries(
          Object.entries(steps).map(([id, props]) => [
            id,
            { ...props, active: false },
          ])
        ) as TSteps
      setSteps(() => ({
        ...markAllStepsAsInactive(),
        [stepId]: {
          ...steps[stepId],
          active: true,
        },
      }))
    },
    [setSteps, steps]
  )

  const markStepAsDirty = useCallback(
    (stepId: ESteps) => {
      setSteps((prevState) => ({
        ...prevState,
        [stepId]: {
          ...prevState[stepId],
          dirty: true,
        },
      }))
    },
    [setSteps]
  )

  const [assessedEmployee, setAssessedEmployee] = useState<IItem>()
  useEffect(() => {
    if (certificateQuery.data) {
      setAssessedEmployee(certificateQuery.data.BeurteilterMitarbeiter)
    }
  }, [certificateQuery.data, assessedEmployee])

  const [activeSection, setActiveSection] = useState<
    | ECertificateSections
    | `${ECertificateSections.CUSTOM_TASK_BLOCKS}__${number}`
  >(ECertificateSections.CERTIFICATE_TYPE)

  const [activeEvaluationSentenceId, setActiveEvaluationSentenceId] = useState<
    number | null
  >(null)

  const [drawerState, setDrawerState] = useState<IDrawer>({
    categoriesIds: [],
    subCategoriesIds: [],
    selectedEvaluationId: null,
    selectedJobId: null,
    selectedTaskId: null,
  })

  const scrollDrawerIntoView = useRef(false)
  const highlightSelectedEvaluationRow = useRef(false)

  const [selectedJob, setSelectedJob] = useState<IJob>()

  const certificateTypeId = certificateQuery.data?.CatZeugnisTyp.ID
  const evaluationAvailable =
    certificateTypeId !== ECertificateTypes.INTERMEDIARY_CONFIRMATION &&
    certificateTypeId !== ECertificateTypes.CONFIRMATION

  return (
    <CertificateContext.Provider
      value={{
        steps,
        markStepAsActive,
        markStepAsDirty,

        educationAvailable: Boolean(certificateQuery.data?.AusbildungsText),
        evaluationAvailable,
        updateCertificate,
        certificateUpdateStatus: certificateMutation.status,

        certificate: certificateQuery.data,
        refetchCertificate: certificateQuery.refetch,

        employee: employeeQuery.data,
        refetchEmployee: employeeQuery.refetch,

        assessedEmployee,
        setAssessedEmployee,
        activeSection,
        setActiveSection,
        activeEvaluationSentenceId,
        setActiveEvaluationSentenceId,
        isDone:
          certificateQuery.data?.CatBearbeitungsStatus.Code ===
            ECertificateStatuses.DONE ?? true,

        drawerState,
        setDrawerState,

        scrollDrawerIntoView,
        highlightSelectedEvaluationRow,

        selectedJob,
        setSelectedJob,
      }}
    >
      {children}
    </CertificateContext.Provider>
  )
}

function useCertificateContext(): TCertificateContext {
  const ctx = useContext(CertificateContext)

  if (!ctx) {
    throw new Error(
      "useCertificateContext should be used within CertificateProvider."
    )
  }

  return ctx
}

export { CertificateProvider, useCertificateContext }
