import classNames from "classnames"
import * as _ from "lodash-es"
import { ChangeEvent, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"

import { Find as FindIcon } from "assets/icons"

type TProps = {
  onChange: (value: string) => void
  inputClassName?: string
  disabled?: boolean
  initialValue?: string
}

export default function SearchBar(props: TProps): JSX.Element {
  const { onChange, inputClassName = "", disabled, initialValue } = props

  const { t } = useTranslation("searchBarComponent")

  const refInput = useRef(null)

  useEffect(() => {
    if (initialValue && refInput?.current) {
      // @ts-ignore
      refInput.current.value = initialValue
    }
  }, [initialValue])

  return (
    <div>
      <label htmlFor="certificates" className="sr-only">
        {t("label")}
      </label>
      <div
        className={classNames([
          "relative flex flex-grow items-stretch",
          "focus-within:z-10",
        ])}
      >
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <FindIcon className="icon" />
        </div>
        <input
          // defaultValue={ initialValue }
          ref={refInput}
          {...{ disabled }}
          type="text"
          name="certificates"
          id="certificates"
          className={classNames([
            "block",
            "h-10 w-full",
            "rounded-md",
            "border border-black pl-10",
            "focus:border-blue-500 focus:ring-blue-500",
            inputClassName,
            disabled && "bg-gray-200",
          ])}
          placeholder={`${t("placeholder")}`}
          onChange={_.debounce(({ target }: ChangeEvent<HTMLInputElement>) => {
            onChange(target.value)
          }, 300)}
        />
      </div>
    </div>
  )
}
