import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import ComboboxUser from "@/components/data/ComboboxUser"
import Button from "@/components/elements/Button"
import Combobox from "@/components/forms/Combobox"
import SelectGeneric from "@/components/forms/SelectGeneric"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@/components/ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  FormServerMessage,
} from "@/components/ui/form"
import { Label } from "@/components/ui/label"
import { useCurrentUserContext } from "context/CurrentUserContext"
import {
  ECertificateTypes,
  useCertificateTypes,
  useEmployee,
  useEmployeesForTable,
} from "hooks/data"
import { cn } from "utils"

import useCreateNewCertificateForm from "./useCreateNewCertificateForm"

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  setCertificateSearchTerm: (input: string) => void
}

const CreateNewCertificateModal: FC<Props> = ({
  open,
  setOpen,
  setCertificateSearchTerm,
}) => {
  const { submit, values, form, status } = useCreateNewCertificateForm()

  const { UILanguage } = useCurrentUserContext()
  const { t: tForm } = useTranslation("certificatesPage", {
    keyPrefix: "createCertificateModal",
  })

  const [searchTerm, setSearchTerm] = useState("")

  const certificateTypesQuery = useCertificateTypes()
  const certificateTypeOptions =
    certificateTypesQuery.data?.map((type) => ({
      value: type.CatZeugnisTypID,
      label: type.BezeichnungML[UILanguage],
    })) || []

  const { data: employee } = useEmployee(values.employeeId)

  const { data: employees } = useEmployeesForTable(
    {
      $top: 50,
      $orderby: `EmployeeName asc`,
      searchterm: searchTerm,
    },
    {
      staleTime: 0,
    }
  )
  const employeeOptions = employees?.map(
    ({ EmployeeName, PersonalNumber, ID }) => ({
      value: ID,
      primary: EmployeeName,
      label: PersonalNumber,
    })
  )

  useEffect(() => {
    const chosenEmployee = employees?.find(({ ID }) => ID === values.employeeId)

    if (chosenEmployee && !values.certificateType) {
      form.setValue(
        "certificateType",
        chosenEmployee?.ExitDate
          ? ECertificateTypes.WORK
          : ECertificateTypes.INTERMEDIARY_WORK
      )
    }
  }, [employees, form, values.certificateType, values.employeeId])

  // we're redirecting after success
  const submittingNewCertificate = status === "loading" || status === "success"

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <Form {...form}>
          <form onSubmit={submit}>
            <DialogHeader
              title={tForm("title")}
              description={tForm("description")}
            />

            <div className="grid gap-4 py-8">
              <FormField
                name="employeeId"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <Label>{tForm("labelEmployee")}</Label>
                    <FormControl>
                      <Combobox
                        defaultOption={employeeOptions?.find(
                          (item) => item.value === field.value
                        )}
                        onSearchTermChange={setSearchTerm}
                        placeholder={tForm("searchEmployees")}
                        options={employeeOptions}
                        onChange={(option) => {
                          field.onChange(option?.value)
                        }}
                      />
                    </FormControl>
                    <FormMessage />

                    <div className="min-h-[20px]">
                      {employee?.EigeneZeugnisse?.length ? (
                        <button
                          className={cn([
                            "ml-auto block text-sm italic",
                            "cursor-pointer underline",
                          ])}
                          onClick={() => {
                            setCertificateSearchTerm(
                              `${employee?.Vorname} ${employee?.Nachname}`
                            )
                            setOpen(false)
                          }}
                        >
                          {tForm("messageShowCertificates")}
                        </button>
                      ) : (
                        <>
                          {searchTerm && (
                            <span
                              className={cn([
                                "block text-right text-sm italic",
                                "text-gray-500",
                              ])}
                            >
                              {tForm("messageNoCertificates")}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                name="certificateType"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <Label>{tForm("certificateType")}</Label>
                    <FormControl>
                      <SelectGeneric
                        {...field}
                        onChange={(option) =>
                          option.value && field.onChange(option.value)
                        }
                        placeholder={tForm("placeholderCertificateType")}
                        options={certificateTypeOptions}
                        value={certificateTypeOptions.find(
                          (option) => option.value === field.value
                        )}
                        isLoading={certificateTypesQuery.isLoading}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <DialogHeader description={tForm("optional")} className="mt-4" />

              <FormField
                name="lineManagerId"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <Label>{tForm("lineManager")}</Label>
                    <FormControl>
                      <ComboboxUser {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="localHRUserId"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <Label>{tForm("localHRUser")}</Label>
                    <FormControl>
                      <ComboboxUser {...field} searchRole="HR" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormServerMessage />

            <DialogFooter>
              <Button
                secondary
                onClick={() => {
                  setOpen(false)
                }}
              >
                {tForm("cancel")}
              </Button>
              <Button type="submit" disabled={submittingNewCertificate}>
                {submittingNewCertificate ? tForm("creating") : tForm("create")}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default CreateNewCertificateModal
