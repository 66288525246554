import dayjs from "dayjs"
import { FC } from "react"
import { useTranslation } from "react-i18next"

import { ECertificateStatuses } from "hooks/data"
import { cn } from "utils"

import { CertificateRow } from "./CertificatesTable"

function getLabelClassName(code?: ECertificateStatuses) {
  switch (code) {
    case "done":
      return "labelGreen"
    case "in_progress":
      return "labelYellow"
    default:
      return "labelGray"
  }
}

type Props = {
  data: CertificateRow
}

const CertificateRowStatusPill: FC<Props> = ({ data }) => {
  const { t } = useTranslation("certificatesPage")

  const { StatusCode, StatusName, Termin } = data
  const isOverdue = dayjs(Termin).add(1, "day").isBefore(dayjs())
  const text = t("certificateStatuses.overdue")

  if (isOverdue && StatusCode !== ECertificateStatuses.DONE) {
    return (
      <span
        className={cn(
          "labelRed mr-auto flex shrink-0 place-items-center text-xs font-semibold"
        )}
      >
        {text}
      </span>
    )
  }
  return (
    <span
      className={cn([
        getLabelClassName(StatusCode),
        "flex w-max text-xs font-semibold",
      ])}
    >
      {StatusName}
    </span>
  )
}

export default CertificateRowStatusPill
