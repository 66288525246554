import { useEffect, useState } from "react"

import { useUsers } from "hooks/data"
import { EUserRoles } from "src/constants"
import { IUser } from "types"

import Combobox from "../forms/Combobox"

type Props = {
  onChange: (value?: number, user?: IUser) => void
  value?: number
  searchName?: string
  searchRole?: keyof typeof EUserRoles
  getInitialUser?: (value: IUser) => void
  label?: string
  hasReset?: boolean
}

const ComboboxUser = ({
  onChange,
  value,
  searchName: initialSearchTerm,
  searchRole,
  getInitialUser,
  label,
  hasReset,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm)

  const role = searchRole ? EUserRoles[searchRole] : undefined

  const usersQuery = useUsers(
    {
      $top: 50,
      searchrole: role,
      $orderby: "Vorname asc",
      searchterm: searchTerm,
    },
    {
      staleTime: 0,
    }
  )

  const options = usersQuery.data?.map(({ BenutzerID, Vorname, Nachname }) => ({
    value: BenutzerID,
    primary: `${Vorname} ${Nachname}`,
  }))

  const handleChange = (optionValue?: number) => {
    const selectedUser = usersQuery.data?.find(
      ({ BenutzerID }) => BenutzerID === optionValue
    )
    if (selectedUser) {
      onChange(optionValue, selectedUser)
    } else {
      setSearchTerm("")
      onChange(undefined)
    }
  }

  const initialValue = options?.find((option) => option.value === value)

  useEffect(() => {
    if (usersQuery.isSuccess && value) {
      const selectedUser = usersQuery.data?.find(
        ({ BenutzerID }) => BenutzerID === value
      )
      getInitialUser && selectedUser && getInitialUser(selectedUser)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersQuery])

  return (
    <Combobox
      showReset={hasReset}
      label={label}
      onSearchTermChange={setSearchTerm}
      options={options}
      onChange={(option) => {
        handleChange(option?.value)
      }}
      defaultOption={initialValue}
    />
  )
}

export default ComboboxUser
