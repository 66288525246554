import { useMemo, useState } from "react"

import { useEmployeesForTableInfinite } from "hooks/data"
import { EUserRoles } from "src/constants"
import { IUser } from "types"

import Combobox from "../forms/Combobox"

type Props = {
  onChange: (value?: number, user?: IUser) => void
  value?: number
  searchName?: string
  searchRole?: keyof typeof EUserRoles
  getInitialUser?: (value: IUser) => void
  label?: string
  hasReset?: boolean
}

const ComboboxEmployee = ({
  onChange,
  value,
  searchName: initialSearchTerm,
  // getInitialUser,
  label,
  hasReset,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm)

  const { data } = useEmployeesForTableInfinite(
    {
      $top: 50,
      $orderby: `EmployeeName asc`,
      searchterm: searchTerm,
    },
    {
      staleTime: 0,
    }
  )
  console.log(data)

  const options = useMemo(() => {
    const o = data?.pages
      .map((page) =>
        page.data.map((employee) => ({
          primary: employee.EmployeeName,
          value: employee.ID,
        }))
      )
      .flat()
    return o ?? []
  }, [data])

  const handleChange = (optionValue?: number) => {
    // const selectedUser = usersQuery.data?.find(
    //   ({ BenutzerID }) => BenutzerID === optionValue
    // )
    onChange(optionValue)
  }

  const initialValue = options?.find((option) => option.value === value)

  // useEffect(() => {
  //   if (isSuccess && value) {
  //     const selectedUser = usersQuery.data?.find(
  //       ({ BenutzerID }) => BenutzerID === value
  //     )
  //     getInitialUser && selectedUser && getInitialUser(selectedUser)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  console.log(initialValue)

  return (
    <Combobox
      showReset={!!hasReset}
      label={label}
      onSearchTermChange={setSearchTerm}
      options={options}
      onChange={(option) => {
        handleChange(option?.value)
      }}
      defaultOption={initialValue}
    />
  )
}

export default ComboboxEmployee
